// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase :{
    apiKey: "AIzaSyARwMsvBxD1MZnkXg7Um0rH15YpN1MlK-w",
    authDomain: "termoder-1dba8.firebaseapp.com",
    databaseURL: "https://termoder-1dba8-default-rtdb.firebaseio.com",
    projectId: "termoder-1dba8",
    storageBucket: "termoder-1dba8.appspot.com",
    messagingSenderId: "138392498120",
    appId: "1:138392498120:web:5359edd33b719e72ff1afd",
    measurementId: "G-S3Y3V2Z1SJ"
  } 
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
